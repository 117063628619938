.App {
  text-align: center;
}

.control-buttons {
  position: relative;
  z-index: 1000;
}

.container {
  margin: 0px auto;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
