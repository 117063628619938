html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* #root {
  position: relative;
  z-index: 2;
  background: none;
} */

#back-button {
  position: absolute;
  z-index: 4;
  top: 0;
}

video {
  object-fit: cover !important;
  left: 0 !important;
  top: 0 !important;
}

.scrollable::-webkit-scrollbar {
  background: transparent;
}
